import Cookie from 'js-cookie';
import { isDev, isLocal, isProduction, isTest } from './environment';
const COOKIE_NAME = 'useOldLogin';

export const updateOldLoginCookie = (val: boolean) => {
  if (val) {
    Cookie.set(COOKIE_NAME, true, { expires: 1 });
  } else {
    Cookie.remove(COOKIE_NAME);
  }

  if (isLocal) {
    console.log('Old login cookie set to', !!val);
  }
};

export const shouldUseOldLogin = (): boolean => {
  return Cookie.get(COOKIE_NAME) === 'true';
};

export const getNewLoginUrl = () => {
  let targetUrl = 'https://konto.meinschiff.com/de/auth/sign-in';

  if (isLocal || isDev || isTest) {
    targetUrl = 'https://konto.aws.test.meinschiff.com/de/auth/sign-in';
  }

  return targetUrl;
};
