import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const DialogWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #85cee4;
  text-align: left;
`;

const DialogHeadline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Dialog = ({ open = false, headline, onClose, children }) => {
  return (
    <DialogWrapper hidden={!open}>
      <div className="main-app without-header">
        <DialogHeadline>
          <h1 className="headline__h1">{headline}</h1>
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              onClose();
            }}
          >
            X
          </button>
        </DialogHeadline>
        <div>{children}</div>
      </div>
    </DialogWrapper>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  headline: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Dialog;
