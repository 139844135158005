// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed, observable, action } from 'mobx';
import { autobind } from 'core-decorators';

import { PACKAGE_SUB_TYPE_NEWSPAPER } from '../../config/constants';

import NewspaperBookingRequest from '../../models/BookingRequest/NewspaperBookingRequest';
import NewspaperList from '../../models/NewspaperList';

import Headline from '../Headline';
import HeroImage from '../HeroImage';
import InfoBox from '../InfoBox';
import LoadingIndicator from '../LoadingIndicator';
import BookingAssitant from '../BookingAssistant/NewspaperBookingAssistant';
import BookingConfirmation from '../BookingConfirmation/NewspaperBookingConfirmation';
import BookingList from '../BookingList/NewspaperPackage';

import type MasterStore from '../../stores/MasterStore';
import type BookingStore from '../../stores/BookingStore';
import type PackageStore from '../../stores/PackageStore';
import type TypeStore from '../../stores/OfferOrPackageTypeStore';
import type Booking from '../../models/Booking';
import type BookedPackage from '../../models/BookedPackage';
import tracking from '../../tracking';
import { tealiumDateFromDateObject, tealiumIdFromString, tealiumValue } from '../../utils/tracking';

type Props = {
  masterStore: MasterStore,
  bookingStore: BookingStore,
  packageStore: PackageStore,
};

@inject('masterStore', 'bookingStore', 'packageStore', 'breadcrumbRouterStore')
@observer
export default class PageNewspaper extends Component<Props> {
  static breadcrumb = 'Zeitungen';

  @observable bookable: NewspaperList;
  @observable bookingRequest: NewspaperBookingRequest;
  @observable showBooking: boolean = false;
  @observable bookedPackages: BookedPackage[];
  @observable
  page: {
    headline: string,
    image: string,
    text: string,
  };

  componentDidMount() {
    const { masterStore } = this.props;
    this.store.fetchList().then(
      action(() => {
        this.bookedPackages = masterStore.packagesOfType(PACKAGE_SUB_TYPE_NEWSPAPER);
        this.bookable = new NewspaperList(this.store.items, this.store.meta);
        this.bookingRequest = new NewspaperBookingRequest(masterStore.masterData.travelParty, this.bookable);
        this.page = this.store.meta;
        tracking.viewItem(this.props.breadcrumbRouterStore, {
          category: this.bookable.analyticsCategory,
          categoryId: tealiumIdFromString(this.bookable.analyticsCategory),
          discount: '0.00',
          code: tealiumIdFromString(this.bookable.analyticsCategory),
          name: this.bookable.analyticsCategory,
          operator: {
            code: 'intern',
            name: 'intern-meinschiff',
          },
          quantity: '1',
          startDate: tealiumDateFromDateObject(new Date()),
          value: tealiumValue(this.bookable.price),
        });
      })
    );
  }

  @computed get store(): TypeStore {
    const { packageStore } = this.props;
    return packageStore.storeForType('newspaper');
  }

  @autobind @action handleBookingClick() {
    console.log(this.bookingRequest);
    tracking.addToCart(this.bookingRequest.selectedPaperIds.length, this.bookable.price);
    this.showBooking = true;
  }

  @autobind @action handleCloseBookingConfirmation() {
    this.bookingRequest.reset();
    this.showBooking = false;
  }

  @autobind @action handleSubmitBookingDialog() {
    const { bookingStore } = this.props;

    bookingStore.handleBookingRequest(this.bookingRequest).then((successful) => {
      if (successful) tracking.bookingRequest(this.bookingRequest);
    });
  }

  @autobind @action handleBookingCancelClick(newspaper: Booking) {
    this.bookingRequest.cancelBooking(newspaper);
    this.showBooking = true;
  }

  render() {
    const { masterStore } = this.props;
    const { masterData } = masterStore;

    if (!this.page) return <LoadingIndicator />;

    if (this.showBooking) {
      return (
        <div className="page">
          <div className="l-row">
            <div className="l-col double">
              <BookingConfirmation
                bookingRequest={this.bookingRequest}
                onConfirm={this.handleSubmitBookingDialog}
                onCloseConfirmation={this.handleCloseBookingConfirmation}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="page page-newspaper">
        <Headline title={this.page.headline} />
        <HeroImage src={this.page.image} alt="" className="l-mod-sub" />
        <div className="l-row">
          <div className="l-col double">
            <p dangerouslySetInnerHTML={{ __html: this.page.text }} />
          </div>
        </div>
        <div className="l-row">
          <div className="l-col double l-mod">
            {this.bookedPackages ? (
              <BookingList
                header="Bereits reservierte Zeitungspakete:"
                bookings={this.bookedPackages.slice()}
                onCancelBooking={this.handleBookingCancelClick}
                disabled={masterStore.masterData.cruiseIsRunning}
              />
            ) : null}
            {masterData.getsFreeNewspaper ? (
              <InfoBox noIcon>
                <div>
                  <h2>Bitte beachten Sie:</h2>
                  <div>
                    Als Suitengast erhalten Sie für die Dauer der Reise täglich eine kostenlose Tageszeitung. Sie haben
                    dazu die Möglichkeit, die Printversion oder die Digitale Version zu wählen:
                    <ul>
                      <li>
                        <span>
                          Printversion: Bitte wählen Sie entsprechend unten Ihre gewünschte Zeitung aus. Diese wird
                          Ihnen dann täglich auf die Kabine gebracht.
                        </span>
                      </li>
                      <li>
                        <span>
                          Digitale Version: Bitte wählen Sie täglich direkt an Bord im Meine Reise Portal die von Ihnen
                          gewünschte digitale Tageszeitung aus. Die Kosten für eine digitale Tageszeitung pro Tag
                          erstatten wir Ihnen anschließend. Wenn Sie die digitale Version wünschen, dann wählen Sie hier
                          bitte keine Zeitung aus.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </InfoBox>
            ) : null}
            {this.bookable && this.bookingRequest ? (
              <BookingAssitant
                bookable={this.bookable}
                bookingRequest={this.bookingRequest}
                onBooking={this.handleBookingClick}
                disabled={!this.bookable.isBookable}
                buttonText={this.bookable.isBookable ? 'Zur Reservierung' : this.bookable.bookingStatus}
              />
            ) : (
              <LoadingIndicator />
            )}
          </div>
          {['Mein Schiff 3', 'Mein Schiff 4'].includes(masterStore.masterData.ship) ? (
            <div className="l-col right"></div>
          ) : (
            <div className="l-col right">
              <h2>
                Nachhaltiges Lesen – Die digitale Zeitung an Bord der <i>Mein Schiff</i> Flotte
              </h2>

              <i className="newspaper-visual" />

              <p>
                An Bord der <i>Mein Schiff</i> Flotte haben Sie die Möglichkeit zwischen verschiedenen tagesaktuellen
                digitalen Zeitungen und Zeitschriften zu wählen. Ihre Zeitung oder Zeitschrift erhalten Sie dabei im
                gewohnten Layout der gedruckten Ausgabe und können sie auf Ihrem eigenen Smartphone Tablet-PC oder
                Laptop lesen. Das schont nicht nur die Umwelt sondern auch Ihren Geldbeutel. Eine Vorbestellung ist hier
                nicht nötig. Die digitale Zeitung ist direkt an Bord über das Bordportal reservierbar.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
